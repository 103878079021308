const Overlay = (props) => {
    return (<>        
        <div className="overlay" style={{width: props.width + 'px', height: props.height + 'px', marginTop: '-' + props.height + 'px'}}>
        <div style={{ display: props.showInstructions ? "none" : "block" }}>
            {props.leftScore !== -1 && (
                <span className="score" style={{ top: "10px", left: "45px", position: "absolute", backgroundColor: props.leftScoreColor }}>
                    {props.leftScore}
                </span>
            )}
            {props.rightScore !== -1 && (
                <span className="score" style={{ top: "10px", right: "45px", position: "absolute", backgroundColor: props.rightScoreColor }}>
                    {props.rightScore}
                </span>
            )}
            {props.totalScore !== -1 && (
                <span className="score" style={{ top: "10px", left: "50%", transform: "translateX(-50%)", position: "absolute", backgroundColor: props.totalScoreColor }}>
                    {props.totalScore}
                </span>
            )}
            {props.redFlag && (
                <span style={{
                    width: '50px',
                    height: '50px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    display: 'inline-block',
                    border: '2px solid white',
                    position: 'absolute',
                    bottom: '10px',
                    left: '10px'
                }}></span>
            )}
            {props.imageUrl && (
                <img 
                    src={props.imageUrl} 
                    style={{ 
                        maxWidth: "260px", 
                        maxHeight: "340px", 
                        backgroundColor: "#050505a1", 
                        position: "absolute", 
                        bottom: "10px", 
                        right: "10px", 
                        padding: "5px", 
                        borderRadius: "15px", 
                        transform: props.isLookingLeft ? 'scaleX(-1)' : 'none' 
                    }}
                />
            )}
            </div>
            <div className="instructions" style={{height:"100%", display: props.showInstructions ? "flex" : "none"}}>
                <span style={{color:"white"}}>{props.message}</span>
                <img src={props.imageUrl} style={{height: props.imageUrl != null ? "60%" : "0"}}/>
            </div>
        </div>
    </>);
};

export default Overlay;